// Package Utilities
import { Col, Grid } from '@devstart/react-bootstrap';
import { graphql } from 'gatsby';
import type { TFunction } from 'i18next';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';

// Local Utilities
import Button from '@ui/Button';
import { ButtonTypes } from '@ui/Button/button-types';
import { FlashMessages } from '@components/Flash/redux/flash-messages';
import Loader from '../../../components/helpers/loader';
import LearnLayout from '../../../components/layouts/learn';
import {
  AllChallengeNode,
  ChallengeMeta,
  ChallengeNode,
  CompletedChallenge,
  User
} from '../../../redux/prop-types';
import ChallengeTitle from '../components/challenge-title';
import Hotkeys from '../components/hotkeys';
import VideoPlayer from '../components/video-player';
import {
  challengeMounted,
  submitChallenge,
  updateChallengeMeta,
  updateSolutionFormValues
} from '../redux/actions';
import {
  completedChallengesIdsSelector,
  isChallengeCompletedSelector,
  isNotAllowedChallengeModalOpenSelector
} from '../redux/selectors';

// Styles
import {
  createFlashMessage,
  removeFlashMessage
} from '../../../components/Flash/redux';
import { userSelector } from '../../../redux/selectors';
import BreadCrumb from '../components/bread-crumb';
import ChallengeDescription from '../components/challenge-description';
import '../video.css';
import ContentChallengeLayout from '../../../components/layouts/content-challenge-layout';

// Redux Setup
const mapStateToProps = createSelector(
  isChallengeCompletedSelector,
  completedChallengesIdsSelector,
  userSelector,
  isNotAllowedChallengeModalOpenSelector,
  (
    isChallengeCompleted: boolean,
    completedChallenges: CompletedChallenge[],
    user: User,
    isNotAllowedChallenge: boolean
  ) => ({
    isChallengeCompleted,
    completedChallengeIds: completedChallenges.map(({ id }) => id),
    user,
    isNotAllowedChallenge
  })
);
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      updateChallengeMeta,
      createFlashMessage,
      removeFlashMessage,
      challengeMounted,
      updateSolutionFormValues,
      submitChallenge
    },
    dispatch
  );

// Types
interface ShowVideoProps {
  challengeMounted: (arg0: string) => void;
  data: { challengeNode: ChallengeNode; allChallengeNode: AllChallengeNode };
  description: string;
  createFlashMessage: typeof createFlashMessage;
  isChallengeCompleted: boolean;
  submitChallenge: () => void;
  pageContext: {
    challengeMeta: ChallengeMeta;
  };
  t: TFunction;
  updateChallengeMeta: (arg0: ChallengeMeta) => void;
  isNotAllowedChallenge: boolean;
}

interface ShowVideoState {
  subtitles: string;
  downloadURL: string | null;
  videoIsLoaded: boolean;
}

// Component
class ShowVideoOnly extends Component<ShowVideoProps, ShowVideoState> {
  public static readonly displayName: string = 'ShowVideo';
  private _container: HTMLElement | null | undefined;

  constructor(props: ShowVideoProps) {
    super(props);
    this.state = {
      subtitles: '',
      downloadURL: null,
      videoIsLoaded: false
    };
  }

  componentDidMount(): void {
    const {
      challengeMounted,
      data: {
        challengeNode: {
          challenge: { title, challengeType, helpCategory }
        }
      },
      pageContext: { challengeMeta },
      updateChallengeMeta
    } = this.props;
    updateChallengeMeta({
      ...challengeMeta,
      title,
      challengeType,
      helpCategory
    });
    challengeMounted(challengeMeta.id);
    this._container?.focus();
  }

  componentDidUpdate(prevProps: ShowVideoProps): void {
    const {
      data: {
        challengeNode: {
          challenge: { title: prevTitle }
        }
      }
    } = prevProps;
    const {
      challengeMounted,
      data: {
        challengeNode: {
          challenge: { title: currentTitle, challengeType, helpCategory }
        }
      },
      pageContext: { challengeMeta },
      updateChallengeMeta
    } = this.props;
    if (prevTitle !== currentTitle) {
      updateChallengeMeta({
        ...challengeMeta,
        title: currentTitle,
        challengeType,
        helpCategory
      });
      challengeMounted(challengeMeta.id);
    }
  }

  render() {
    const {
      data: {
        challengeNode: {
          challenge,
          challenge: { title, phase, description, superBlock, block, videoId }
        }
      },
      submitChallenge,
      pageContext: {
        challengeMeta: { nextChallengePath, prevChallengePath }
      },
      t,
      isChallengeCompleted,
      isNotAllowedChallenge
    } = this.props;

    const blockNameTitle = `${t(
      `intro:${superBlock}.phases.${phase}.blocks.${block}.title`
    )} - ${title}`;

    const breadCrumbItems = [
      {
        key: 'superblock-title',
        value: t(`intro:${superBlock}.title`),
        redirectsTo: `/learn/${superBlock}`
      },
      {
        key: 'superblock-phase',
        value: t(`intro:${superBlock}.phases.${phase}.title`),
        redirectsTo: `/learn/${superBlock}/#${block}`
      },
      {
        key: 'superblock-theme',
        value: t(`intro:${superBlock}.phases.${phase}.blocks.${block}.title`),
        redirectsTo: `/learn/${superBlock}/#${block}`
      },
      {
        key: 'superblock-challenge',
        value: title,
        redirectsTo: ''
      }
    ];

    return (
      <Hotkeys
        executeChallenge={() => {
          submitChallenge();
        }}
        innerRef={(c: HTMLElement | null) => (this._container = c)}
        nextChallengePath={nextChallengePath}
        prevChallengePath={prevChallengePath}
      >
        <LearnLayout challenge={challenge}>
          <Helmet
            title={`${blockNameTitle} | ${t('learn.learn')} | DEVStart`}
          />

          <ContentChallengeLayout
            disabledContinueButton={!isChallengeCompleted}
          >
            <Grid className='navigation-container-video'>
              <BreadCrumb breadCrumbItems={breadCrumbItems} />
            </Grid>
            <Grid className='container-video'>
              <ChallengeTitle>{title}</ChallengeTitle>
              <Col sm={12}>
                <div className='video-wrapper'>
                  {!this.state.videoIsLoaded ? (
                    <div className='video-placeholder-loader'>
                      <Loader />
                    </div>
                  ) : null}
                  <VideoPlayer videoId={videoId} />
                </div>
                <hr />
              </Col>
              <Col sm={12}>
                <ChallengeDescription
                  description={description}
                  style={{ padding: 0 }}
                />
                <div className='video-quiz-options'>
                  <Button
                    id='big-button'
                    buttonType={ButtonTypes.Quaternary}
                    disabled={isNotAllowedChallenge}
                    onClick={() => {
                      this.props.createFlashMessage({
                        message: FlashMessages.CongratsChallengeComplete,
                        type: 'success',
                        variables: { dismissible: true }
                      });

                      submitChallenge();
                    }}
                  >
                    {t('buttons.go-to-next')}
                  </Button>
                </div>
              </Col>
            </Grid>
          </ContentChallengeLayout>
        </LearnLayout>
      </Hotkeys>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ShowVideoOnly));

export const query = graphql`
  query OnlyVideoChallenge($slug: String!) {
    challengeNode(challenge: { fields: { slug: { eq: $slug } } }) {
      challenge {
        videoId
        title
        phase
        description
        challengeType
        helpCategory
        superBlock
        certification
        block
        fields {
          blockName
          slug
        }
        translationPending
      }
    }
  }
`;
